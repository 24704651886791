.table {
    margin-bottom: 0;
}

.badge {
    padding: 0.5em;
    border-radius: 0.25em;
}

.btn {
    margin-right: 0.5em;
}