.homepage {
  padding-bottom: 50px;
}

.card {
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.button {
  background-color: #3e86e6;
  border: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #336bb3;
}

.text-primary {
  color: #3e86e6 !important;
}

.homepage-card {
  width: 100%;
  max-width: 100%;
}

.lead {
  font-size: 1.1rem;
  color: #666;
}

.display-4, .display-5 {
  color: #3e86e6;
  font-weight: bold;
}

.text-center {
  text-align: center;
}