.container {
    max-width: 82%;
    margin: 0 auto;
}

.card {
    margin-bottom: 1.5rem;
}

.table {
    margin-bottom: 0;
}

.table th, .table td {
    vertical-align: middle;
    text-align: center;
}

.table thead th {
    background-color: #f8f9fa;
}

.badge {
    font-size: 1rem;
    padding: 0.5em;
}

.table-date-separator {
    background-color: #f8f9fa;
    font-weight: bold;
}

.date-separator {
    border-top: 2px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
}

.card-header {
    background-color: #3e86e6;
    color: white;
}

.card-body {
    padding: 1.5rem;
}

.btn {
    margin: 0.25rem;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
}

.timeline-date {
    width: 100%;
    padding: 10px 0;
    font-weight: bold;
    border-bottom: 1px solid #dee2e6;
}

.timeline-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 100%;
}

.timeline-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #f8f9fa;
}

.timeline-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.timeline-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.timeline-time {
    font-size: 0.9rem;
    color: #6c757d;
}

.timeline-status {
    font-size: 0.9rem;
}