/* InventoryDashboard.module.css */

.cardTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

.headerText {
    font-weight: bold;
    color: #3e86e6;
}

.btnPrimary {
    background-color: #3e86e6;
    border: none;
    transition: background-color 0.3s ease;
}

.btnPrimary:hover {
    background-color: #336bb3;
}

.btnSecondary {
    background-color: #6c757d;
    border: none;
    transition: background-color 0.3s ease;
}

.btnSecondary:hover {
    background-color: #5a6268;
}