.card {
    margin-bottom: 20px;
}

.card-header {
    background-color: #007bff;
    color: #fff;
}

.card-body {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

.table {
    margin-bottom: 0;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.tabs {
    margin-bottom: 20px;
}

.tabs .nav-item .nav-link {
    color: #007bff;
}

.tabs .nav-item .nav-link.active {
    background-color: #007bff;
    color: #fff;
}