/* ManageInventory.css */

/* General container styling */
.container {
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/* Header styling */
h1, h2 {
    font-weight: bold;
    color: #3e86e6;
    text-align: center;
    margin-bottom: 2rem;
}

/* Card styling */
.card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

/* Button styling */
.btn-primary {
    background-color: #3e86e6;
    border: none;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #336bb3;
}

.btn-secondary {
    background-color: #6c757d;
    border: none;
    transition: background-color 0.3s ease;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

/* Form styling */
.form-label {
    font-weight: bold;
    color: #3e86e6;
}

.form-control {
    border: 1px solid #ced4da;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
    border-color: #3e86e6;
    box-shadow: 0 0 0 0.2rem rgba(62, 134, 230, 0.25);
}

/* Table styling */
.table {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.table th,
.table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
}

.table th {
    background-color: #3e86e6;
    /* color: #fff; */
    font-weight: bold;
}

.table tbody tr:hover {
    background-color: #f1f1f1;
}

.table .badge {
    font-size: 1rem;
    padding: 0.5em;
}

/* Filter section styling */
.filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.filter-section .form-group {
    margin-right: 1rem;
}

.filter-section .form-group:last-child {
    margin-right: 0;
}

/* Utility classes */
.mb-3 {
    margin-bottom: 1rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-5 {
    margin-top: 2rem;
}

.mb-5 {
    margin-bottom: 2rem;
}

/* Custom scrollbar */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #3e86e6;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}