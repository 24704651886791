.tv-display-container {
    /* background-color: #000; */
    color: #fff;
    height: 100vh;
    overflow-y: auto;
    padding: 20px;
    width: 100%;
}

.device-card {
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    height: 100%;
}

.device-card h5 {
    font-size: 1.5rem;
}

.device-card p {
    font-size: 1.2rem;
}

.spare-device {
    border: 2px solid #ffcc00;
}

.text-warning {
    color: #ffcc00;
}

.text-center {
    text-align: center;
}