.directoryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%; /* Ensure the card takes full width of its column */
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

.button {
    background-color: #3e86e6;
    border: none;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #336bb3;
}