.navbar {
    background: #3e86e6;
    padding: 10px 20px;
}

.navLink {
    font-weight: bold;
    font-size: 1.1rem;
    color: #ffffff; /* Light color for better visibility against the gradient background */
    transition: color 0.3s ease-in-out;
    text-decoration: none;
    margin-right: 15px;
}

.navLink:hover {
    color: #ffd700; /* Gold color on hover */
}

.searchInput {
    width: 250px;
}

.searchResults {
    z-index: 1000; /* Ensure the search results dropdown is above other content */
}

.loginButton {
    background-color: #629fee;
    border: none;
    transition: background-color 0.3s ease-in-out;
}

.loginButton:hover {
    background-color: #4a8ee7;
}

.userButton {
    background: none;
    border: none;
}