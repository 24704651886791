@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  /* Transition for the whole page */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Use viewport height to ensure it covers the full screen */
}

.content-wrap {
  flex-grow: 1;
  /* This ensures that the content area takes up all available space */
}

footer {
  flex-shrink: 0;
  /* Prevent the footer from shrinking */
  height: 12%;

}

footer p {
  font-size: 16px;
  /* Adjust the font size as needed */
  margin-bottom: 0;
  /* Reduces space below the paragraph if necessary */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

button:hover {
  transform: scale(1.02);
  /* Slightly enlarge on hover */
  transition-duration: 100ms;
}

.navbar,
.nav-link {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-link:hover {
  transform: translateY(-2px);
  /* Slight lift on hover */
  transition-duration: 50ms;
}

/* Transition for tables */
.table {
  transition: all 0.3s ease-in-out;
}

/* Add transitions to table rows */
.table tbody tr {
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.table tbody tr:hover {
  transform: scale(1.01);
  /* Slightly enlarge on hover */
  transition-duration: 50ms;
}

.search-container {
  flex-grow: 1;
  max-width: 300px;
  position: relative;
}

.search-results {
  z-index: 1000;
  /* Ensures the dropdown is above other elements */
  max-height: 300px;
  /* Adds a maximum height */
  overflow-y: auto;
  /* Makes the dropdown scrollable if it exceeds the max height */
  margin-top: 40px;
  /* Adjusts the position to prevent overlap with the profile picture */
}

/* Add this to your main CSS file or a new CSS module for the layout */
html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding-left: 3rem;
  padding-right: 3rem;
}

.footer {
  background: linear-gradient(to right, #78ada4, #4a6b65);
  color: white;
  text-align: center;
  padding: 1rem;
  width: 100%;
}

.table {
  color: black;
}

/* Adjust table cell padding and font size for smaller screens */
@media (max-width: 576px) {
  .table th,
  .table td {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .content {
    flex: 1;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.receive-red {
  color: red !important;
  font-weight: bold !important;
}

.receive-yellow {
  color: orange !important;
  font-weight: bold !important;
}
